html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*="language-"]):before,
code:not([class*="language-"]):after {
  content: "`";
}
code[class*="language-"] {
  padding: 0;
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 0 !important;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}
.my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */

  margin-bottom: 30px;
}
.dx-scheduler-date-table-cell {
  height: 25px;
}

.dx-scheduler-time-panel-cell {
  height: 25px;
}

.dx-hide {
  visibility: hidden;
  height: 0px;
}

.dxm-background {
  fill: rgba(0, 0, 0, 0);
  stroke: none;
}
